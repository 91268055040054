import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import {canAccess} from "./security/roles";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "dashboards/minimal",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Notification",
          path: "notifications",
          component: ()=>import("@/views/notification/Notification")
        },
        {
          name: "FranqDashBorad",
          path: "franq/dashboard",
          component: ()=>import("@/views/dashboards/DashBoardFranq")
        },
        {
          name: "AdminDashborad",
          path: "admin/dashboard",
          component: ()=>import("@/views/dashboards/DashboardAdmin")
        },
        {
          name: "LawyerDashborad",
          path: "lawyer/dashboard",
          component: ()=>import("@/views/dashboards/DashboardLawyer")
        },
        {
          name: "FinancierDashborad",
          path: "financier/dashboard",
          component: ()=>import("@/views/dashboards/DashboardFinancier")
        },
        {
          name: "ReportBase",
          path: "report",
          component: ()=>import("@/views/report/base"),
          children: [
            {
              name: "ReportSubject",
              path: "subject",
              component: ()=>import("@/views/report/ReportSubject")
            },
            {
              name: "ReportPay",
              path: "pay",
              component: ()=>import("@/views/report/ReportPay")
            }
          ]
        },
        {
          name: "ChatBase",
          path: "chat",
          component: ()=>import("@/views/chats/base"),
          children: [
            {
              name: "ChatDashboard",
              path: "",
              component: ()=>import("@/views/apps/chat/Chat")
            },
            {
              name: "Chat",
              path: "chat/:username",
              component: ()=>import("@/views/chats/chat")
            }
          ]
        },
        //Subject
        {
          name: "Subject",
          path: "subject",
          component: ()=> import("@/views/subject/base"),
          children: [
            {
              name: "AprovSubject",
              path: "aprov",
              component: ()=>import("@/views/subject/AprovSubject")
            },
            {
              name: "SubjectFinish",
              path: "finished",
              component: ()=>import("@/views/subject/SubjectFinish")
            },
            {
              name: "SubjectPending",
              path: "pending",
              component: ()=>import("@/views/subject/SubjectPending")
            },
            {
              name: "SubjectDetails",
              path: "details/:id",
              component: ()=>import("@/views/subject/SubjectDetails")
            },
            {
              name: "SubjectCanceled",
              path: "canceled",
              component: ()=>import("@/views/subject/SubjectCanceled")
            },
            {
              name: "PaySubject",
              path: "pay",
              component: ()=>import("@/views/subject/PaySubject")
            },
            {
              name: "ForPaySubject",
              path: "forpay",
              component: ()=>import("@/views/subject/ForPaySubject")
            },
            {
              name: "DeclineSubject",
              path: "decline",
              component: ()=>import("@/views/subject/DeclineSubject")
            },
            {
              name: "Subjectlist",
              path: "Subjectlist",
              component: () => import("@/views/subject/subject"),
            },
            {
              name: "Activitylist",
              path: "Activitylist",
              component: () => import("@/views/subject/activity"),
            },
            {
              name: "Notelist",
              path: "Notelist",
              component: () => import("@/views/subject/note"),
            },
            {
              name: "Momentlist",
              path: "Momentlist",
              component: () => import("@/views/subject/moment"),
            },
            {
              name: "WorkEmaillist",
              path: "WorkEmaillist",
              component: () => import("@/views/subject/workemail"),
            },
            {
              name: "SLawyerlist",
              path: "SLawyerlist",
              component: () => import("@/views/subject/slawyer"),
            },
            {
              name: "Documentlist",
              path: "Documentlist",
              component: () => import("@/views/subject/document"),
            },
          ]
        },
        {
          name: "UserBase",
          path: "user",
          component: ()=> import("@/views/user/BaseUser"),
          children: [
            {
              name: "UserList",
              path: "userlist",
              component: () => import("@/views/user/UserList"),
            },
            {
              name: "LawyerList",
              path: "lawyers",
              component: () => import("@/views/user/lawyers"),
            },
            {
              name: "ClientList",
              path: "ClientList",
              component: () => import("@/views/user/client"),
            },
            {
              name: "ClientInbox",
              path: "ClientInbox",
              component: () => import("@/views/user/inbox"),
            },
            {
              name: "DLawyerList",
              path: "DLawyerList",
              component: () => import("@/views/user/dlawyer"),
            },
            {
              name: "Franq",
              path: "franq",
              component: () => import("@/views/user/Franq"),
            },
            {
              name: "Financiero",
              path: "financy",
              component: () => import("@/views/user/Financiero"),
            },
            {
              name: "Operator",
              path: "operator",
              component: () => import("@/views/user/Operator"),
            },
          ]
        },
          {
            name: "CodifBase",
            path: "codif",
            component: () => import("@/views/codif/base"),
            children: [
              {
                name: "AddFinacierToFranq",
                path: "franq/addFinancier/:id",
                component: () => import("@/views/codif/AddFranqFinancier")
              },
              {
                name: "Subcategories",
                path: "substatus/:id",
                component: () => import("@/views/codif/SubCategory")
              },
              {
                name: "Substatus",
                path: "substatus/:id/:idsc",
                component: () => import("@/views/codif/substatus")
              },
              {
                name: "FranqDetails",
                path: "franqDetails/:id",
                component: () => import("@/views/codif/FranqDetails")
              },
              {
                name: "FinancierDetails",
                path: "financierDetails/:id",
                component: () => import("@/views/codif/FinancierDetails")
              },
              {
                name: "StatusPaymentlist",
                path: "StatusPaymentlist",
                component: () => import("@/views/codif/statuspayment"),
              },
              {
                name: "Specialtylist",
                path: "Specialtylist",
                component: () => import("@/views/codif/specialty"),
              },
              {
                name: "Statuslist",
                path: "Statuslist",
                component: () => import("@/views/codif/status"),
              },
              {
                name: "Citylist",
                path: "Citylist",
                component: () => import("@/views/codif/city"),
              },
              {
                name: "TypeCaselist",
                path: "TypeCaselist",
                component: () => import("@/views/codif/typecase"),
              },
              {
                name: "Categorylist",
                path: "Categorylist",
                component: () => import("@/views/codif/category"),
              },

              {
                name: "Franchiselist",
                path: "Franchiselist",
                component: () => import("@/views/codif/franchise"),
              },
              {
                name: "Statelist",
                path: "Statelist",
                component: () => import("@/views/codif/state"),
              },
              {
                name: "Financierlist",
                path: "Financierlist",
                component: () => import("@/views/codif/financier"),
              },
              {
                name: "FormPaymentlist",
                path: "FormPaymentlist",
                component: () => import("@/views/codif/formpayment"),
              },
            ]
          },
        //Cites
        {
          name: "Cites",
          path: "cites",
          component: () => import("@/views/cite/cite"),
        },
        {
          name: "FranqSt",
          path: "franq/statistics",
          component: () => import("@/views/statistics/statistics_franq"),
        },
        {
          name: "AdminSt",
          path: "admin/statistics",
          component: () => import("@/views/statistics/statistics_admin"),
        },
        {
          name: "CitesClient",
          path: "client/cites",
          component: () => import("@/views/cite/CiteClient"),
        },
        //Receipt
        {
          name: "ReceiptList",
          path: "receipt/receiptlist",
          component: () => import("@/views/receipt/ReceiptList"),
        },
        //Visit
        {
          name: "VisitList",
          path: "visit/VisitList",
          component: () => import("@/views/visit/VisitList"),
        },
        {
          name: "NotificationFinancier",
          path: "notification/financier",
          component: () => import("@/views/notification/NotificationFinancier"),
        },
        {
          name: "DashBoardBase",
          path: "dashboards",
          component: ()=> import("@/views/dashboards/DashboardBase"),
          children: [
            {
              name: "Analytical",
              path: "analytical",
              component: () => import("@/views/dashboards/analytical/Analytical"),
            },
            {
              name: "Modern",
              path: "modern",
              component: () => import("@/views/dashboards/modern/Modern"),
            },
            {
              name: "Demographical",
              path: "demographical",
              component: () => import("@/views/dashboards/demographical/Demographical"),
            },
            {
              name: "Minimal",
              path: "minimal",
              component: () => import("@/views/dashboards/minimal/Minimal"),
            },
          ]
        },
        {
          name: "Roles",
          path: "security",
          component: () => import("@/views/security/roles"),
        },
      ],
    },
    {
      name: "PasswordBase",
      path: "/password",
      component: ()=>import("@/views/password/base"),
      children: [
        {
          name: "Email",
          path: "email",
          component: ()=>import("@/views/password/email")
        },
        {
          name: "SendEmail",
          path: "token",
          component: ()=>import("@/views/password/token")
        },
        {
          name: "NewPassword",
          path: "newPassword",
          component: ()=>import("@/views/password/newPassword")
        }
      ]
    },
    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/authentication/Login"),
        },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "BoxedRegister",
          path: "boxedregister",
          component: () => import("@/views/authentication/BoxedRegister"),
        },
        {
          name: "FullRegister",
          path: "fullregister",
          component: () => import("@/views/authentication/FullRegister"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  if (Vue.prototype.$store !== undefined){
    let role = Vue.prototype.$store.state.userinfo !== undefined? Vue.prototype.$store.state.userinfo.groups[0].name: undefined;
    next(canAccess(role, to.path));
  }
  else{
    next();
  }
});

export default router;
