<template>
  <v-app id="adminpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />
  </v-app>

</template>

<script>

export default {
  name: 'App',

  components: {
    
  },
    created () {
    // Leer la información de estado en sessionStorage cuando se carga la página
    if (sessionStorage.getItem("store") ) {
        this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    } 
 
         // Guarde la información en vuex en sessionStorage cuando se actualice la página
    window.addEventListener("beforeunload",()=>{
        sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })
  }

};
</script>
<style>
    .font-size{
        font-size: 1rem !important;
    }
    .input-tel__input{
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        caret-color: dodgerblue !important;
        height: 3.5rem !important;
    }
    .country-selector__input{
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        height: 3.5rem !important;
    }
    .country-selector__country-flag{
        margin: auto 0 !important;
        position: absolute !important;
        top: 28px !important;
        left: 11px !important;
        z-index: 1 !important;
        cursor: pointer !important;
    }
</style>
