import Vue from "vue";
import Vuex from "vuex";
import axios  from 'axios';

Vue.use(Vuex);

/*export const urlmaster = `http://localhost:8000`;
export const recurso = 'http://localhost:8000';
export const websocket  = 'ws://localhost:8000/ws/graphql';
export const url = 'http://localhost:8080';*/

export const urlmaster = `https://legalia-ws.qa.idooproject.com`;
export const recurso = 'https://legalia-ws.qa.idooproject.com';
export const websocket = 'wss://legalia-ws.qa.idooproject.com/ws/graphql';
export const url = 'https://legalia.qa.idooproject.com';

axios.defaults.headers.post['Content-Type'] = 'application/json';

export default new Vuex.Store({
  state: {
        idFinancieroFinancier: -1,
        traz: [],
        Sidebar_drawer: null,
        Customizer_drawer: false,
        //SidebarColor:  "rgb(220, 51, 46)", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        SidebarColor:  "black",
        SidebarBg: "",
        navbarColor: "white",
        setHorizontalLayout: false, // Horizontal layout
        token:null,
        islogin:false,
        userlist:[],
        serverurl:urlmaster,
        formpaymentlist:[],
        typecaselist:[],
        statelist:[],
        selectstate:null,
        selectfranchise:null,
        selectclient:null,
        messagebyclientlist:[],
        urlwork:recurso,
        messagebylawyerlist:[],
        lawyerlist:[],
        clientlist:[],
        citylist:[],
        statuslist:[],
        subjectlist:[],
        selectsubject:[],
        activitylist:[],
        categorylist:[],
        financierlist:[],
        statuspaymentlist:[],
        francierlist:[],
        visitstadistic:null,
        specialtylist:[],
        userinfo:{},
        roles: [],
        permissionslist: [],
        financieralist: [],
        franqlist: [],
        operatorlist: [],
        chats: [],
        loading: false,
        success: false,
        failed: false,
        emailrec: "",
        usernamerec: "",
        loadingSubjects: false,
        loadingFranquicias: false,
        loadingFinanciera: false,
        loadingCategory: false,
        loadingCity: false,
        loadingFormPay: false,
        loadingStatusPay: false,
        loadingSpeciality: false,
        loadingStatus: false,
        loadingState: false,
        loadingTypeCase: false,
        loadingRoles: false,
        loadingUser: false,
        loadingLawyers: false,
        loadingFranquiciarias: false,
        loadingFinancieras: false,
        loadingClients: false,
        loadingOperators: false,
        loadingContacts: false,
        idCurrent: 0,
        activerole: true,
        franqDetails: {},
        loadingDetails: false,
        loadingDetailsClients: false,
        loadingDetailsLawyers: false,
        loadingDetailsSubjects: false,
        loadingFinancierDetails: false,
        financierDetails: {},
        allcity: [],
        subjectDetails: {},
        loadingSubjectDetails: false,
        notifications: [],
        loadingNotifications: false,
        loadingNotificationsFull: false,
        loadingSubstatus: false,
        invSubject: {},
        notificationsNotRead: [],
        financier_franq: [],
        idSpecificFinancier:-1,
        substatusList: [],
        messages: [],
        messagesFull: [],
        photo: '',
        user: '',
        franchiseFromFranq: -1,
        loadingFinancierAdd: false,
  },
  mutations: {
      SET_INV_SUBJECT(state, payload){
          state.invSubject = payload;
      },
      SET_LOADING_NOTIFICATIONS(state, payload){
          state.loadingNotifications = payload;
      },
      SET_LOADING_NOTIFICATIONS_FULL(state, payload){
          state.loadingNotificationsFull = payload;
      },
      SET_NOTIFICATIONS(state, payload){
          state.notifications = payload;
      },
      SET_SUBJECT_DETAILS(state, payload){
          state.subjectDetails = payload;
      },
      SET_LOADING_SUBJECT_DETAILS(state, payload){
          state.loadingSubjectDetails = payload;
      },
      SET_ALL_CITY(state, payload){
          state.allcity = payload;
      },
      SET_FINANCIER_DETAILS(state, payload){
          state.financierDetails = payload;
      },
      SET_LOADING_FINANCIER_DETAILS(state, payload){
          state.loadingFinancierDetails = payload;
      },
      SET_LOADING_DETAILS(state, payload){
          state.loadingDetails = payload;
      },
      SET_LOADING_DETAILS_LAWYERS(state, payload){
          state.loadingDetailsLawyers = payload;
      },
      SET_LOADING_DETAILS_CLIENTS(state, payload){
          state.loadingDetailsClients = payload;
      },
      SET_LOADING_DETAILS_SUBJECTS(state, payload){
          state.loadingDetailsSubjects = payload;
      },
      SET_FRANQ_DETAILS(state, payload){
          state.franqDetails = payload;
      },
      SET_ACTIVE_ROLE(state, payload){
          state.activerole = payload;
      },
      SET_ID_CURRENT(state, payload){
          state.idCurrent = payload;
      },
      SET_LOADING_CONTACTS(state, payload){
          state.loadingContacts = payload;
      },
      SET_LOADING_USER(state, payload){
          state.loadingUser = payload;
      },
      SET_LOADING_LAWYERS(state, payload){
          state.loadingLawyers = payload;
      },
      SET_LOADING_FRANQUICIARIAS(state, payload){
          state.loadingFranquiciarias = payload;
      },
      SET_LOADING_FINANCIERAS(state, payload){
          state.loadingFinancieras = payload;
      },
      SET_LOADING_CLIENTS(state, payload){
          state.loadingClients = payload;
      },
      SET_LOADING_OPERATORS(state, payload){
          state.loadingOperators = payload;
      },
      SET_LOADING_ROLES(state, payload){
          state.loadingRoles = payload;
      },
      SET_LOADING_TYPE_CASE(state, payload){
          state.loadingTypeCase = payload;
      },
      SET_LOADING_STATE(state, payload){
          state.loadingState = payload;
      },
      SET_LOADING_CATEGORY(state, payload){
        state.loadingCategory = payload;
      },
      SET_LOADING_CITY(state, payload){
        state.loadingCity = payload;
      },
      SET_LOADING_FORM_PAY(state, payload){
        state.loadingFormPay = payload;
      },
      SET_LOADING_STATUS_PAY(state, payload){
        state.loadingStatusPay = payload;
      },
      SET_LOADING_SPECIALITY(state, payload){
        state.loadingSpeciality = payload;
      },
      SET_LOADING_STATUS(state, payload){
        state.loadingStatus = payload;
      },
    SET_LOADING_FINANCIERA(state, payload){
        state.loadingFinanciera = payload;
    },
    SET_LOADING_FRANQUICIAS(state, payload){
        state.loadingFranquicias = payload;
    },
    SET_LOADING_SUBJECTS(state, payload){
        state.loadingSubjects = payload;
    },
    SET_NAME_REC(state, payload){
      state.usernamerec = payload;
    },
    SET_EMAIL_REC(state, payload){
        state.emailrec = payload;
    },
    SET_LOADING(state, payload){
        state.loading = payload;
    },
    SET_SUCCESS(state, payload){
        state.success = payload;
    },
    SET_FAILED(state, payload){
        state.failed = payload;
    },
    SET_CHATS(state, payload){
        state.chats = payload;
    },
    SET_FINANCIERA_LIST(state, payload){
        state.financieralist = payload;
    },
    SET_FRANQ_LIST(state, payload){
        state.franqlist = payload;
    },
    SET_OPERATOR_LIST(state, payload){
        state.operatorlist = payload;
    },
    SET_ROLES(state, payload){
        state.roles = payload;
    },
    SET_PERMISSIONS_LIST(state, payload){
        state.permissionslist = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_TOKEN(state, payload) {
      state.setHorizontalLayout = payload;
    },
    financierDetailsQuery(state, id){
          state.loadingFinancierDetails = true;
          axios.post(`${urlmaster}/graphql/`,
              {query: `{
                 financierDetails(id:${id}){
                    description
                    aprov{
                      id
                      date
                      description
                      totalCost
                      typecase{
                        description
                      }
                      client{
                        description
                      }
                    }
                    decl{
                      id
                      date
                      description
                      totalCost
                      typecase{
                        description
                      }
                      client{
                        description
                      }
                    }
                    pag{
                      id
                      date
                      description
                      totalCost
                      typecase{
                        description
                      }
                      client{
                        description
                      }
                    }
                    ppag{
                      id
                      date
                      description
                      totalCost
                      typecase{
                        description
                      }
                      client{
                        description
                      }
                    }
                  }
                }`
              }
          ).then((response)=>{
              state.financierDetails = response.data.data.financierDetails;
          }).catch((error)=>console.log(error))
              .finally(()=>{
                  state.loadingFinancierDetails = false;
              });
      },
      subjectDetailsQuery(state, id){
          state.loadingSubjectDetails = true;
          axios.post(`${urlmaster}/graphql/`,
              {query: `{
                    subjectsdetails(id: ${id}){
                        description
                        date
                        status{
                          description
                        }
                        finacier{
                          description
                        }
                        statuspayment{
                          description
                        }
                        typecase{
                          description
                        }
                        typepayment{
                          description
                        }
                        client{
                          user{
                            firstName
                            lastName
                            email
                          }
                        }
                        lawyers{
                          user{
                            firstName
                            lastName
                          }
                          specialty{
                            description
                          }
                        }
                        activitys{
                          description
                          date
                          notification
                        }
                        notes{
                          description
                          date
                          notification
                        }
                        moments{
                          description
                          date
                        }
                        workemails{
                          description
                        }
                    }
                }`
              }
          ).then((response)=>{
              state.subjectDetails = response.data.data.subjectsdetails;
          }).catch((error)=>console.log(error))
              .finally(()=>{
                  state.loadingSubjectDetails = false;
              });
      },
    franqDetailsQuery(state, id){
        state.loadingDetails = true;
        state.loadingDetailsClients = true;
        state.loadingDetailsLawyers = true;
        state.loadingDetailsSubjects = true;
        axios.post(`${urlmaster}/graphql/`,
            {query: `{
                 franquiseDetails(id:${id}){
                    name
                    subjects{
                      id
                      description
                      date
                      totalCost
                      typecase{
                        description
                      }
                      status{
                        description
                      }
                    }
                    clients{
                      id
                      description
                      date
                      user{
                        firstName
                        lastName
                      }
                    }
                    lawyers{
                      id
                      specialty{
                        description
                      }
                      user{
                        firstName
                        lastName
                        username
                      }
                    }
                  }
                }`
            }
        ).then((response)=>{
            state.franqDetails = response.data.data.franquiseDetails;
        }).catch((error)=>console.log(error))
            .finally(()=>{
                state.loadingDetails = false;
                state.loadingDetailsClients = false;
                state.loadingDetailsLawyers = false;
                state.loadingDetailsSubjects = false;
            });
    },
    login: async function (state, objuser)
    {
     await axios({
      method: 'post',
      url: `${urlmaster}/login/`,
      data:JSON.stringify(objuser),
      headers: {'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
      responseType: 'json',
      responseEncoding: 'utf8'

    })
      .then((response)=> {
      state.token = response.data.token;        
      state.islogin = true;
      return state.islogin;
          })
      .catch((error)=> {
        console.log(error);
      });
     return state.islogin;
    },
    getAllPermissions(state){
        axios.post(`${urlmaster}/graphql/`,
            {query: `{
                permissions{
                    id
                    name
                }
            }`
            }
            ).then((response)=>{
                state.permissionslist = response.data.data.permissions;
        }).catch((error)=>console.log(error));
    },
      getSpecificFinancier(state, id){
          axios.post(`${urlmaster}/graphql/`,
              {query: `{
                getSpecificFinancier(id:${id})
            }`
              }
          ).then((response)=>{
              state.idSpecificFinancier = response.data.data.getSpecificFinancier;
          }).catch((error)=>console.log(error));
      },
      getFinancierFranq(state, idFranq=-1){
          state.loadingFinancierFranq=true;
          axios.post(`${urlmaster}/graphql/`,
              {query: `{
                financierFranq(idFranq:${idFranq}){
                    financier{
                      id
                      description
                      active
                      state{
                        id
                        description
                      }
                      franq{
                        id
                      }
                    }
                    forPay
                    pay
                    canceled
                    fact
                }
              }`
              }
          ).then((response)=>{
              state.financier_franq = response.data.data.financierFranq;
          }).catch((error)=>console.log(error))
          .finally(()=>{
              state.loadingFinancierFranq=false;
          });
      },
      getAllCitySearch(state){
          axios.post(`${urlmaster}/graphql/`,
              {query: `{
                allCity{
                    id
                    description
                    state{
                      description
                    }
                }
            }`
              }
          ).then((response)=>{
              state.allcity = response.data.data.allCity;
          }).catch((error)=>console.log(error));
      },
      getAllRoles(state, filter){
          state.loadingRoles = true;
          axios.post(`${urlmaster}/graphql/`,
              {query: `{
                roles(filter:${filter}){
                    id
                    name
                    active
                    groupPtr {
                      id
                      name
                      permissions{
                        id
                        name
                      }
                    }
                  }
                }`
              }
          ).then((response)=>{
              state.roles = response.data.data.roles;
          }).catch((error)=>console.log(error))
              .finally(()=>{
                  state.loadingRoles = false;
              });
      },
      getToken(state, email){
          axios.post(`${urlmaster}/graphql/`,
              {query: `{
                forget(code: "${email}"){
                    id
                    code
                    user{
                      id
                      username
                      email
                    }
                  }
                }`
              }
          ).then((response)=>{
              state.emailrec = response.data.data.user.email;
          }).catch((error)=>console.log(error));
      },
    getAllUser(state, filter=false)
    {
        state.loadingUser = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
       allusergeneral(filter:${filter}){
           id,
           username,
           firstName,
           lastName,
           email,
           isSuperuser,
           isActive,
           groups {
              id,
              name
           }
           franq
           financier
           speciality
          }
     }`
     }
    )
      .then((response)=> {
          let userlist = [];
           for(let i = 0;i<response.data.data.allusergeneral.length;i++)
              {
                    let aux = {
                      id:response.data.data.allusergeneral[i].id,
                      avatar:"1.png",
                      firstname:response.data.data.allusergeneral[i].firstName,
                      lastname:response.data.data.allusergeneral[i].lastName,
                      username:response.data.data.allusergeneral[i].username,
                      useremail:response.data.data.allusergeneral[i].email,
                      isSuperuser:response.data.data.allusergeneral[i].isSuperuser,
                      isActive:response.data.data.allusergeneral[i].isActive,
                      isStaff:response.data.data.allusergeneral[i].isStaff,
                      selectrole:response.data.data.allusergeneral[i].groups[0].name,
                      franq:response.data.data.allusergeneral[i].groups[0].franq,
                      financier:response.data.data.allusergeneral[i].groups[0].financier,
                      speciality:response.data.data.allusergeneral[i].groups[0].speciality
                    };
                    userlist.push(aux);
              }
          state.userlist = userlist;
      })
      .catch((error)=> {
        console.log(error);
      })
         .finally(()=>{
             state.loadingUser = false;
         });
    },
      getAllFinanciera(state, filter)
      {
          state.loadingFinancieras = true;
          axios.post(`${urlmaster}/graphql/`,
              {query:`{
                           financiero(filter:${filter}){
                                id
                                username
                                firstName
                                lastName
                                email
                                active
                                financiera{
                                  id
                                  description
                                }
                                userPtr{
                                  groups{
                                    id
                                    name
                                    permissions{
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                            }`
              }
          )
              .then((response)=> {
                  let userlist = [];
                  for(let i = 0;i<response.data.data.financiero.length;i++)
                  {
                      let aux = {
                          id:response.data.data.financiero[i].id,
                          avatar:"1.png",
                          firstname:response.data.data.financiero[i].firstName,
                          lastname:response.data.data.financiero[i].lastName,
                          username:response.data.data.financiero[i].username,
                          useremail:response.data.data.financiero[i].email,
                          isSuperuser:response.data.data.financiero[i].isSuperuser,
                          active:response.data.data.financiero[i].active,
                          isStaff:response.data.data.financiero[i].isStaff,
                          selectrole:response.data.data.financiero[i].userPtr.groups[0].name,
                          financiera:response.data.data.financiero[i].financiera.description
                      };
                      userlist.push(aux);
                  }
                  state.financieralist = userlist;
              })
              .catch((error)=> {
                  console.log(error);
              })
              .finally(()=>{
                  state.loadingFinancieras = false;
              });
      },
      getFinancieraUser (state, username)
      {
          state.loadingFinancieras = true;
          axios.post(`${urlmaster}/graphql/`,
              {query:`{
                               getFinanciera(username: "${username}"){
                                    id
                                    description
                               }
                           }`
              }
          )
              .then(function (response) {
                  state.idCurrent = response.data.data.getFinanciera.id
              })
              .catch(function (error) {
                  console.log(error);
              });
      },
      getAllFranq(state, filter)
      {
          state.loadingFranquiciarias = true;
          axios.post(`${urlmaster}/graphql/`,
              {query:`{
                           franq(filter:${filter}){
                                id
                                username
                                firstName
                                lastName
                                email
                                active
                                franchise{
                                  id
                                  description
                                }
                                userPtr{
                                  groups{
                                    id
                                    name
                                    permissions{
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                            }`
              }
          )
              .then((response)=> {
                  let userlist = [];
                      const f = response.data.data.franq;
                      for (let i = 0; i < f.length; i++) {
                          let aux = {
                              id: f[i].id,
                              avatar: "1.png",
                              firstname: f[i].firstName,
                              lastname: f[i].lastName,
                              username: f[i].username,
                              useremail: f[i].email,
                              isSuperuser: f[i].isSuperuser,
                              active: f[i].active,
                              isStaff: f[i].isStaff,
                              selectrole: f[i].userPtr.groups[0].name,
                              franchise: f[i].franchise.description
                          };
                          userlist.push(aux);
                      }
                  state.franqlist = userlist;
              })
              .catch((error)=> {
                  console.log(error);
              })
              .finally(()=>{
                  state.loadingFranquiciarias = false;
              });
      },
      getAllOperator(state, filter)
      {
          state.loadingOperators = true;
          axios.post(`${urlmaster}/graphql/`,
              {query:`{
                           operator(filter:${filter}){
                                id
                                username
                                firstName
                                lastName
                                email
                                active
                                userPtr{
                                  groups{
                                    id
                                    name
                                    permissions{
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                            }`
              }
          )
              .then((response)=> {
                  let userlist = [];
                      for (let i = 0; i < response.data.data.operator.length; i++) {
                          let aux = {
                              id: response.data.data.operator[i].id,
                              avatar: "1.png",
                              firstname: response.data.data.operator[i].firstName,
                              lastname: response.data.data.operator[i].lastName,
                              username: response.data.data.operator[i].username,
                              useremail: response.data.data.operator[i].email,
                              isSuperuser: response.data.data.operator[i].isSuperuser,
                              active: response.data.data.operator[i].active,
                              isStaff: response.data.data.operator[i].isStaff,
                              selectrole: response.data.data.operator[i].userPtr.groups[0].name
                          };
                          userlist.push(aux);
                      }
                  state.franqlist = userlist;
              })
              .catch((error)=> {
                  console.log(error);
              })
              .finally(()=>{
                  state.loadingOperators = false;
              });
      },
      getNotifications(state, obj)
      {
          if(obj.full === true){
              state.loadingNotifications = true;
          }
          else{
              state.loadingNotificationsFull = true;
          }
          axios.post(`${urlmaster}/graphql/`,
              {query:`{
                      getNotifications(id:${obj.id}, typeMessage:"${obj.type}", full: ${obj.full}){
                            id
                            subject
                            description
                            date
                            read
                            readAdmin
                            subjectModel{
                              description
                            }
                            client{
                              id
                              user{
                                id
                              }
                            }
                      }
                    }`
              }
          ).then((response)=> {
                  if(obj.full === true){
                    state.notifications = response.data.data.getNotifications;
                  }
                  else{
                      state.notificationsNotRead = response.data.data.getNotifications;
                  }
              }).catch((error)=> {
                  console.log(error);
          }).finally(()=>{
              if(obj.full === true){
                  state.loadingNotifications = false;
              }
              else{
                  state.loadingNotificationsFull = false;
              }
          });
      },
    getUserInfo(state,token)
    {
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      userinfo(token:"${token}")
      {
        user{
          id,
          username,
          isSuperuser,
          firstName,
          lastName,
          email,
          groups{
            id,
            name
          }
        }
      }
    }`
    }
    )
      .then((response)=> {
        state.userinfo = response.data.data.userinfo[0].user;
      }).catch((error)=> {
        console.log(error);
      });
    },
      getInv(state,id)
        {
            axios.post(`${urlmaster}/graphql/`,
                {query:`{
                          inv(id:${id})
                        }`
                }
            ).then((response)=> {
                state.invSubject = response.data.data.inv;
            }).catch((error)=> {
                console.log(error);
            });
        },
    getAllSpecialty(state, filter)
    {
        state.loadingSpeciality = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      specialtys(filter:${filter})
      {
         id,
         description,
         active
      }
    }`
    }
    )
      .then((response)=> {
          state.specialtylist = [];
          state.specialtylist = response.data.data.specialtys;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
             state.loadingSpeciality = false;
      });
    },
    getAllMessagebyClient(state,id)
    {
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      messagebyclient(id:${id})
      {
        id,
        description,
        subject, 
        date, 
        read ,
        client {
          id,
          description,
           user{
            username,
            firstName,
            lastName,
            email
           }
        }
      }
    }`
    }
    )
      .then((response)=> {
          state.messagebyclientlist = [];
          state.messagebyclientlist = response.data.data.messagebyclient;
      })
      .catch((error)=> {
        console.log(error);
      });
    },
    getAllMessagebyLawyer(state,id)
    {
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      messagebylawyer(id:${id})
      {
        id,
        description,
        subject, 
        date ,
        client {
          id,
          description, 
          user{
            username,
            firstName,
            lastName,
            email
          }
        }
      }
    }`
    }
    )
      .then((response)=> {
          state.messagebylawyerlist = [];
          state.messagebylawyerlist = response.data.data.messagebylawyer;
      })
      .catch((error)=> {
        console.log(error);
      });
      
    },
      activeRole(state, name){
          state.activerole = true;
          axios.post(`${urlmaster}/graphql/`,
              {query:
               `{
                activerole(name:"${name}")
              }`
              }
          )
              .then((response)=> {
                  state.activerole = response.data.data.activerole;
              })
              .catch((error)=> {
                  console.log(error);
              });

      },
     getAllSubject(state,obj)
    {
      state.loadingSubjects = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:obj.status !== ''?
             `{
      subjects(id:${obj.id},idClient:${obj.id_client},idLawyer:${obj.id_lawyer},idFinancier:${obj.id_financier},status:"${obj.status}",typeStatus:${obj.type},filter:${obj.filter}, idFranq:${obj.id_franq})
      {
        id,
        date,
        description,
        active,
        client{
          id,
          photo,
          active,
          description,
          user{
            id,
            username,
            firstName,
            lastName,
            email
          }
        },
        lawyers{
          id,
          photo,
          active,
          specialty{
            id,
            description
          },
          user{
            id,
            username,
            firstName,
            lastName,
            email
          }
        },
        activitys
        {
          id,
          description,
          date,
          notification
        }
        notes
        {
          id,
          description,
          date,
          notification
        },
        documents
        {
          id,
          description,
          file,
          date
        },
        moments
        {
          id,
          description,
          date
        }
        workemails
        {
          id,
          description
        },
        status{
            id,
            description
        },
        typecase{
            id,
            description,
            cost
        },
        statuspayment{
            id,
            description
        },
        typepayment{
            id,
            description
        },
        finacier{
            id,
            description
        },
        franq{
            id
        }
        cause,
        totalCost
      }
    }`
             :`{
      subjects(id:${obj.id},idClient:${obj.id_client},idLawyer:${obj.id_lawyer},idFinancier:${obj.id_financier},filter:${obj.filter},idFranq:${obj.id_franq})
      {
        id,
        date,
        description,
        active,
        client{
          id,
          photo,
          active
          user{
            id,
            username,
            firstName,
            lastName,
            email
          }
        },
        lawyers{
          id,
          photo,
          active,
          specialty{
            id,
            description
          },
          user{
            id,
            username,
            firstName,
            lastName,
            email
          }
        },
        activitys
        {
          id,
          description,
          date,
          notification
        }
        notes
        {
          id,
          description,
          date,
          notification
        },
        documents
        {
          id,
          description,
          file,
          date
        },
        moments
        {
          id,
          description,
          date
        }
        workemails
        {
          id,
          description
        },
        status{
            id,
            description
        },
        typecase{
            id,
            description,
            cost
        },
        statuspayment{
            id,
            description
        },
        typepayment{
            id,
            description
        },
        finacier{
            id,
            description
        }
        franq{
            id
        },
        cause,
        totalCost
      }
    }`
    }
    )
      .then((response)=> {
          state.subjectlist = [];
          state.subjectlist = response.data.data.subjects;
      })
      .catch((error)=> {
          console.log(error);
      }).finally(()=>{
          state.loadingSubjects = false;
      });
    },
    getAllLawyer(state,obj)
    {
        state.loadingLawyers = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:obj.id===undefined?
             `{
      lawyers(filter:${obj.filter},idFranq:${obj.idFranq})
          {
            id,
            description,
            date,
            photo, 
            active,
            user{
                id,
                username,
                firstName,
                lastName,
                email, 
                groups{
                    id,
                    name
                }
            },
            franchise{
              id,
              description
            },
            specialty{
                id, 
                description
            },
            franq{
                id
            }
          }
        }`
             :`{
      lawyers(idFranchise:${obj.id},filter:${obj.filter},idFranq:${obj.idFranq})
          {
            id,
            description,
            date,photo,
            active,
            user {
                id,
                username,
                firstName,
                lastName,
                email, 
                groups{
                    id,
                    name
                }
            },
            franchise{
              id,
              description
            },
            specialty{
                id, 
                description
            }
          }
    }`
    }
    )
      .then((response)=> {
          state.lawyerlist = response.data.data.lawyers;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
             state.loadingLawyers = false;
         });
    },
    getAllStatus(state, filter)
    {
        state.loadingStatus = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      status(filter:${filter})
      {
         id,
         description,
         active
      }
    }`
    }
    )
      .then((response)=> {
          state.statuslist = [];
          state.statuslist = response.data.data.status;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingStatus = false;
         });
    },
    getAllState(state, filter)
    {
        state.loadingState = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      states(filter:${filter}) {
        id,
        description,
        citySet
        {
            id,
            description, 
            active
        }, 
        active
      }
    }`
    }
    )
      .then((response)=> {
          state.statelist = [];
          state.statelist = response.data.data.states;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingState = false;
      });
    },
    getAllClient(state, obj={filter:false, idFranq:-1, id_lawyer:-1})
    {
        state.loadingClients = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      clients(filter:${obj.filter},idFranq:${obj.idFranq},idLawyer:${obj.id_lawyer})
      {
        id,
        description,
        active,
        date,
        photo,
        user
        {
          id,
          username,
          firstName,
          lastName,
          email,
          groups
          {
            id,
            name
          }
        },
        franq{
          id
        },
        lawyers{
          id
          user{
            firstName
            lastName
          }
        }
      }
    }`
    }
    )
      .then((response)=> {  state.clientlist = [];
        state.clientlist = response.data.data.clients;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingClients = false;
      });
    },
    getAllCategory(state, filter)
    {
        state.loadingCategory = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
     categorys(filter:${filter}){
        id,
        active,
        description,
        cost
        }
     }`
    }
    )
      .then((response)=> {
          state.categorylist = [];
          state.categorylist = response.data.data.categorys;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingCategory = false;
      });
    },
    getAllFranchise(state, filter)
    {
     state.loadingFranquicias = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      franchise(filter:${filter})
      {
        id,
        description,
        active,
        city
        {
          id,
          description,
          state
          {
            id,
            description
          }
        }
      }
    }`
    }
    )
      .then((response)=> {
          state.francierlist = [];
          state.francierlist = response.data.data.franchise;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingFranquicias = false;
      });
    },
    getAllFinancier(state, obj={filter:false, idFranq:-1})
    {
        state.loadingFinanciera = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      financiers(filter:${obj.filter},idFranq:${obj.idFranq}) {
        id,
        description,
        state{
          id,
          description
        }, 
        active,
        franq{
          id
        }
      }
    }`
    }
    )
      .then((response)=> {
          state.financierlist = [];
          state.financierlist = response.data.data.financiers;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
             state.loadingFinanciera = false;
         });
    },
    getAllStatusPayment(state, filter)
    {
        state.loadingStatusPay = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      statuspayments(filter:${filter}) {
        id,
        description,
        active
      }
    }`
    }
    )
      .then((response)=> {
          state.statuspaymentlist = [];
          state.statuspaymentlist = response.data.data.statuspayments;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingStatusPay = false;
      });
    },
    getAllFormPayment(state, filter)
    {
        state.loadingFormPay = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
        formpayments(filter:${filter})
        {
          id,
		  description,
		  date,
		  active
        }
      
    }`
    }
    )
      .then((response)=> {
          state.formpaymentlist = [];
          state.formpaymentlist = response.data.data.formpayments;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingFormPay = false;
      });
    },
    getAllCity(state, obj)
    {
        state.loadingCity = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      citys(idState:${obj.id_state},filter:${obj.filter})
      {
        id,
        description,
        active,
        state
        {
          id,
          description
        }
      }
    }`
    }
    )
      .then((response)=> {
          state.citylist = [];
          state.citylist = response.data.data.citys;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingCity = false;
      });
    },
    getAllTypeCase(state, filter)
    {
        state.loadingTypeCase = true;
     axios.post(`${urlmaster}/graphql/`,
     {query:`{
      typecases(filter:${filter}){
        id,
        description,
        active
        }
      }`
    }
    )
      .then((response)=> {
          state.typecaselist = [];
          state.typecaselist = response.data.data.typecases;
      })
      .catch((error)=> {
        console.log(error);
      }).finally(()=>{
          state.loadingTypeCase = false;
      });
    },

  },//Fin mutations*****************************************************************************************************************************************************
  actions: {
    login({commit},obj)
    {
       commit('login',obj);
    },
       AddRole({commit},newproduct)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
                      mutation($nameRole:String,$permissions:String!,$idUser:String) 
                      {
                          addRoleUser(nameRole: $nameRole, permissions: $permissions, idUser: $idUser)
                          {
                                role{
								  id
								  name
								  groupPtr{
									id
									name
									permissions{
									  id
									  name
									}
								  }
								}
                          }
                      } `,
              variables:newproduct
          }) .then((response)=> {
              console.log(response.data);
              commit('getAllRoles', false);
          }).catch((error)=> {
              console.log(error);
          });
      },

       UpdateRole({commit},newproduct)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
                      mutation($id:Int!,$permissions:String!) 
                      {
                          updateRoleUser(id: $id, permissions: $permissions)
                          {
                                role{
								  id
								  name
								  groupPtr{
									id
									name
									permissions{
									  id
									  name
									}
								  }
								}
                          }
                      } `,
              variables:newproduct
          }).then((response)=> {
              console.log(response.data);
              commit('getAllRoles', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
       DeleteRole({commit},newproduct)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
                      mutation($id:Int!) 
                      {
                          deleteRoleUser(id: $id){
                            active
                          }
                      } `,
              variables:newproduct
          }) .then(()=> {
              commit('getAllRoles', false);
          })
              .catch(function (error) {
                  console.log(error);
              });
      },
    LoadNotifications({commit}, obj){
        commit('getNotifications', obj);
    },
    LoadfranqDetails({commit}, id){
        commit('franqDetailsQuery', id);
    },
    LoadFinancierDetails({commit}, id){
        commit('financierDetailsQuery', id);
    },
    LoadFinacieraCurrent({commit}, username){
        commit('getFinancieraUser', username);
    },
    LoadTokenRec({commit},email)
    {
        commit('getToken',email);
    },
    LoadActiveRole({commit}, name){
        commit('activeRole', name)
    },
     LoadUserInfo({commit},token) {
        commit('getUserInfo',token);
    },
      LoadInvInfo({commit},id) {
          commit('getInv',id);
      },
    LoadCity({commit},obj={id:0,filter:false}) {
        commit('getAllCity',obj);
    },
    LoadMessagebyClient({commit},id=0) {
        commit('getAllMessagebyClient',id);
    },
    LoadFinanciero({commit}, filter=false) {
        commit('getAllFinanciera', filter);
    },
    LoadFranq({commit}, filter=false) {
        commit('getAllFranq', filter);
    },
    LoadOperator({commit}, filter=false) {
        commit('getAllOperator', filter);
    },
    LoadFinancierFranq({commit}, idFranq=-1) {
        commit('getFinancierFranq', idFranq);
    },
    LoadSpecificFinancier({commit},id){
        commit('getSpecificFinancier',id);
    },
    LoadMessagebyLawyer({commit},id=0){
        commit('getAllMessagebyLawyer',id);
    },
    LoadLawyer({commit}, obj={id: undefined, filter: false, idFranq:-1})
    {
        commit('getAllLawyer',obj);
    },
    LoadSubject({commit},obj)
    {
        commit('getAllSubject',obj);
    },
    AllCitySearch({commit}){
        commit('getAllCitySearch');
    },
    LoadFranchise({commit}, filter=false)
    {
        commit('getAllFranchise', filter);
    },
    LoadSpecialty({commit}, filter=false)
    {
        commit('getAllSpecialty', filter);
    },
    LoadClient({commit}, obj={filter:false, idFranq:-1, id_lawyer: -1})
    {
        commit('getAllClient', obj);
    },
    LoadStatus({commit}, filter=false)
    {
        commit('getAllStatus', filter);
    },
    LoadUser:function({commit}, filter=false)
    {
        commit('getAllUser', filter);
    },
    LoadFormPayment({commit}, filter=false)
    {
        commit('getAllFormPayment', filter);
    },
    LoadFinancier({commit}, obj={filter:false, idFranq:-1})
    {
        commit('getAllFinancier', obj);
    },
    LoadStatusPayment({commit}, filter=false)
    {
        commit('getAllStatusPayment', filter);
    },
    LoadState({commit}, filter=false) {
        commit('getAllState', filter);
    },
    LoadSubjectDetail({commit}, id) {
    commit('subjectDetailsQuery', id);
    },
    LoadCategory({commit}, filter=false) {
        commit('getAllCategory', filter);
    },
    LoadTypeCase({commit}, filter=false) {
        commit('getAllTypeCase', filter);
    },
    LoadPermissions({commit}){
        commit('getAllPermissions');
    },
    LoadRoles({commit}, filter=false){
        commit('getAllRoles', filter);
    },
    AddUser({commit},newuser)
    {
      let namefunction = `addUser`;
      if (newuser.selectrole == 'Administrador') namefunction = `addAdmin`;

        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($username:String,$email:String,$firstname:String,$lastname:String,$password:String,$selectrole:String)
      {
        ${namefunction} (username:$username,email:$email,firstName:$firstname,lastName:$lastname,password:$password,role:$selectrole)
        {
          newuser {id,username,firstName,lastName,email,isActive }
        }
      }   `,
        variables:newuser
    }).then(() => {
      commit('getAllUser', false);
    }).catch(function (error) {
        console.log(error);
    });
    },
    UpdateUser:  function({commit},updateuser)
    {

        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($id:Int,$username:String,$email:String,$firstname:String,$lastname:String,$selectrole:String,$lastRole:String)
      {
        updateUser (id:$id,username:$username,email:$email,firstName:$firstname,lastName:$lastname,role:$selectrole,lastRole:$lastRole)
        {
          updateuser {
            id,
            username,
            firstName,
            lastName,
            email,
            isActive 
          }
        }
      }   `,
        variables:updateuser
    }).then(()=> {
        commit('getAllUser', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStatusUser({commit},updateuser)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($id:Int)
      {
        updateUserstate (id:$id)
        {
          userstate
        }
      }   `,
        variables:updateuser
    }).then(()=> {
        commit('getAllUser');
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangePassword({commit},updateuser)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: `mutation ($id:Int,$password:String)
          {
            changepassword(id:$id,password:$password)
            {
              updateuser{username,id}
            }
          } `,
        variables:updateuser
    }).then(()=> {
        commit('getAllUser', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
      AddFinanciero({commit, state},newuser)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation($firstname:String,$lastname:String,$username:String,$email:String,$password:String,$financiera:String){
                addFinanciera(firstName:$firstname,lastName:$lastname,username:$username,email:$email,password:$password,financiera:$financiera){
                    info{
                                id
                                username
                                firstName
                                lastName
                                email
                                active
                                financiera{
                                  id
                                  description
                                }
                                userPtr{
                                  groups{
                                    id
                                    name
                                    permissions{
                                      id
                                      name
                                    }
                                  }
                                }
                    }
                }
              }   `,
              variables:newuser.newuser
          }).then((response) => {
               const user = response.data.data.addFinanciera.info;
               let aux = {
                   id:user.id,
                   avatar:"1.png",
                   firstname:user.firstName,
                   lastname:user.lastName,
                   username:user.username,
                   useremail:user.email,
                   isSuperuser:user.isSuperuser,
                   active:user.active,
                   isStaff:user.isStaff,
                   selectrole:user.userPtr.groups[0].name,
                   financiera:user.financiera.description
               };
               state.financieralist = [...state.financieralist, aux];
               if(newuser.location === true)
                   commit('getAllUser', false);
               else
                commit('getAllFinanciera', false);
          }).catch((error)=> {
                  console.log(error);
              });
      },
      UpdateFinanciero({commit},newuser)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id:Int,$firstname:String,$lastname:String,$username:String,$email:String,$password:String,$financiera:String,$lastRole:String){
                updateFinanciera(id:$id,firstName:$firstname,lastName:$lastname,username:$username,email:$email,password:$password,financiera:$financiera,lastRole:$lastRole){
                    info{
                      id
                      firstName
                      lastName
                      username
                      email
                      financiera{
                        id
                        description
                      }
                      active
                      remove
                    }
                  }
              }   `,
              variables:newuser.newuser
          }).then(()=> {
               if(newuser.location === true)
                   commit('getAllUser', false);
               else
                    commit('getAllFinanciera', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
      UpdateMessageRead({commit},obj)
      {
          axios.post(`${urlmaster}/graphql/`, {
              query: ` 
                  mutation($id:Int,$admin:Boolean){
                      changestatereadmessage(id:$id,admin:$admin){
                        message{
                          id
                          date
                          description
                          subject
                          read
                          readAdmin
                          client{
                            id
                            user{
                              id
                            }
                          }
                          subjectModel{
                            description
                          }
                        }    
                      }
                  }`,
              variables:obj
          }).then(()=> {
              commit('getNotifications', obj.obj);
          }).catch((error)=> {
              console.log(error);
          });
      },

      AddFranq({commit},newuser)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($firstname:String,$lastname:String,$username:String,$email:String,$password:String,$franchise:String){
                addFranq(firstName:$firstname,lastName:$lastname,username:$username,email:$email,password:$password,franchise:$franchise){
                    info{
                      id
                      firstName
                      lastName
                      username
                      email
                      franchise{
                        id
                        description
                        city{
                          description
                       }
                      }
                      active
                    }
                  }
              }   `,
              variables:newuser.newuser
          }).then(()=> {
               if(newuser.location === true)
                   commit('getAllUser', false);
               else
                   commit('getAllFranq', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
      UpdateFranq({commit},newuser)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation($id:Int,$firstname:String,$lastname:String,$username:String,$email:String,$password:String,$franchise:String,$lastRole:String){
                updateFranq(id:$id,firstName:$firstname,lastName:$lastname,username:$username,email:$email,password:$password,franchise:$franchise,lastRole:$lastRole){
                    info{
                      id
                      firstName
                      lastName
                      username
                      email
                      franchise{
                        id
                        description
                      }
                      active
                      remove
                    }
                  }
              }   `,
              variables:newuser.newuser
          }).then(()=> {
               if(newuser.location === true)
                   commit('getAllUser', false);
               else
                   commit('getAllFranq', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
      SendEmail({state},email)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation($email:String){
                sendEmail(email:$email){
                    info{
                      id
                      code
                      user{
                        username
                        email
                      }
                    }
                  }
              }   `,
              variables:email
          }).then((resp) => {
              state.success=true;
              state.emailrec = resp.data.data.info.user.email;
              state.usernamerec = resp.data.data.info.user.username;
          }).catch((error)=> {
              state.failed = true;
              state.emailrec = "";
              state.usernamerec = "";
              console.log(error);
          }).finally(()=>{
              state.loading=false;
          });
      },
      ChangePasswordToken:  function({state}, username, password)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation($username:String,$password:String){
                changePasswordRec(username:$username, password:$password){
                    info
                }
              }   `,
              variables:username, password
          }).then(function (resp) {
              state.success=resp.data.data.info;
          }).catch(function (error) {
              state.failed = true;
              state.emailrec = "";
              console.log(error);
          }).finally(()=>{
              state.loading=false;
          });
      },
      AddOperator({commit}, newuser)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation($firstname:String,$lastname:String,$username:String,$email:String,$password:String){
                addOperator(firstName:$firstname,lastName:$lastname,username:$username,email:$email,password:$password){
                    info{
                      id
                      firstName
                      lastName
                      username
                      email
                      active
                    }
                  }
              }   `,
              variables:newuser.newuser
          }).then(()=> {
               if(newuser.location === true)
                   commit('getAllUser', false);
               else
                   commit('getAllOperator', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
      UpdateOperator({commit}, newuser)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation($id:Int,$firstname:String,$lastname:String,$username:String,$email:String,$password:String,$lastRole:String){
                updateOperator(id:$id,firstName:$firstname,lastName:$lastname,username:$username,email:$email,password:$password,lastRole:$lastRole){
                    info{
                      id
                      firstName
                      lastName
                      username
                      email
                      active
                      remove
                    }
                }
              }   `,
              variables:newuser.newuser
          }).then(()=> {
               if(newuser.location === true)
                   commit('getAllUser', false);
               else
                    commit('getAllOperator', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
      DeleteOperator({commit},id)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id: Int)
              {
                deleteOperator(id: $id){
                    state
                }
              }   `,
              variables:id
          }) .then(function () {
              commit('getAllOperator', false);
          }).catch(function (error) {
              console.log(error);
          });
      },
      DeleteFranq:  function({commit},id)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id: Int)
              {
                deleteFranq(id: $id){
                    state
                }
              }   `,
              variables:id
          }) .then(function () {
              commit('getAllFranq', false);
          }).catch(function (error) {
              console.log(error);
          });
      },
      DeleteFinanciero:  function({commit},id)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id: Int)
              {
                deleteFinanciera(id: $id){
                    state
                }
              }   `,
              variables:id
          }) .then(function () {
              commit('getAllFinanciera', false);
          }).catch(function (error) {
              console.log(error);
          });
      },
      ChangeStateFinanciero: function({commit},id)
      {
           axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id: Int)
              {
                changeStateFinanciera(id: $id){
                    state
                }
              }   `,
              variables:id
          }) .then(function () {
              commit('getAllFinanciera', false);
          }).catch(function (error) {
              console.log(error);
          });
      },
      ChangeStateFinanciera({commit},id)
      {
          axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id: Int)
              {
                changeStateFinanciera(id: $id){
                    state
                }
              }   `,
              variables:id
          }) .then(()=> {
              commit('getAllFinanciera', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
      ChangeStateFranq({commit},id)
      {
          axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id: Int)
              {
                changeStateFranq(id: $id){
                    state
                }
              }   `,
              variables:id
          }).then(()=> {
              commit('getAllFranq', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
      ChangeStateOperator({commit},id)
      {
          axios.post(`${urlmaster}/graphql/`, {
              query: ` 
              mutation ($id: Int)
              {
                changeStateOperator(id: $id){
                    state
                }
              }   `,
              variables:id
          }).then(()=> {
              commit('getAllOperator', false);
          }).catch((error)=> {
              console.log(error);
          });
      },
    AddSubjectLawyer({commit},newproduct)
    {

        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation($idsubject:Int,$idlawyer:Int)
      {
        addsubjectlawyer(idsubject:$idsubject,idlawyer:$idlawyer)
        {
          info
          {
            id
          }
        }
      } `,
        variables:newproduct.obj
    }) .then(()=> {
        let params = {...newproduct.params};
        params.id = newproduct.obj.idsubject;
        commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    RemoveSubjectLawyer({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation($idsubject:Int,$id:Int)
      {
        removesubjectlawyer(idsubject:$idsubject,id:$id)
        {
          info
          {
            id
          }
        }
      } `,
        variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
			params.id = newproduct.obj.idsubject;
			commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddMenssage({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation($subject:String,$client:String,$lawyer:String,$description:String)
      {
        addmessage(description:$description,subject:$subject, client:$client, lawyer:$lawyer)
        {
          info
          {
            id,description,subject,read
            lawyer{description}
            client{description}
          }
        }
      } `,
        variables:newproduct
    }) .then(()=> {
        commit('getAllMessagebyClient',newproduct.idclient);
        commit('getAllMessagebyLawyer',newproduct.idlawyer);
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateReadMessage({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($id:Int)
      {
        changestatereadmessage (id:$id)
        {
          state 
        }
      }   `,
        variables:newproduct
    }).then(()=> {
      commit('getAllMessagebyClient',newproduct.id);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddMoment({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$description:String)
          {
            addsubjectmoment(idsubject:$idsubject,description:$description)
            {
              info
              {
                id
              }
            }
          } `,
           variables:newproduct.obj
    }) .then(()=> {
            let params = {...newproduct.params};
			params.id = newproduct.obj.idsubject;
			commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddWorkEmail({commit},newproduct)
    {
         axios.post(`${urlmaster}/graphql/`, {
        query: ` 
          mutation($idsubject:Int,$description:String)
          {
            addsubjectworkemail(idsubject:$idsubject,description:$description)
            {
              info
              {
                id
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
            let params = {...newproduct.params};
			params.id = newproduct.obj.idsubject;
			commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateWorkEmail({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$description:String)
          {
            updateworkemail(id:$id,description:$description)
            {
              info
              {
                id,
                description
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
            let params = {...newproduct.params};
			params.id = newproduct.obj.idsubject;
			commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    RemoveWorkEmail({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$id:Int)
          {
            removesubjectworkemail(idsubject:$idsubject,id:$id)
            {
              info
              {
                id
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
            let params = {...newproduct.params};
			params.id = newproduct.obj.idsubject;
			commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateMoment({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$description:String)
          {
            updatemoment(id:$id,description:$description)
            {
              info
              {
                id
              }
            }
      } `,
        variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    RemoveMoment({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$id:Int)
          {
            removesubjectmoment(idsubject:$idsubject,id:$id)
            {
              info
              {
                id
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddNote({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$description:String)
          {
            addsubjectnote(idsubject:$idsubject,description:$description)
            {
              info
              {
                id,notes
                {
                  id,description,date,notification
                }
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateNote({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$description:String)
          {
            updatenote(id:$id,description:$description)
            {
              info
              {
                id
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    RemoveNote({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$id:Int)
          {
            removesubjectnote(idsubject:$idsubject,id:$id)
            {
              info
              {
                id
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddActivity({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$description:String)
          {
            addsubjectactivity(idsubject:$idsubject,description:$description)
            {
              info
              {
                id,activitys
                {
                  id,description,date,notification
                }
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateActivity({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$description:String)
          {
            updateactivity(id:$id,description:$description)
            {
              info
              {
                id
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    RemoveActivity({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$id:Int)
          {
            removesubjectactivity(idsubject:$idsubject,id:$id)
            {
              info
              {
                id,
                activitys
                {
                  id,
                  description,
                  date,
                  notification
                }
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(()=> {
           let params = {...newproduct.params};
           params.id = newproduct.obj.idsubject;
           commit('getAllSubject',params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddSubject({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idclient:Int,$description:String,$status:String,$financier:String,$typecase:String,$typepayment:String,$statuspayment:String,$lawyer:Int,$cause:String,$cost:Decimal,$idFranq:Int)
          {
            addsubject(idclient:$idclient,description:$description,status:$status,typecase:$typecase,typepayment:$typepayment,financier:$financier,statuspayment:$statuspayment,lawyer:$lawyer,cause:$cause,cost:$cost,franq:$idFranq)
            {
              info
              {
                id,
                description,
                totalCost,
                client{ 
                    id,
                    user{
                      id,
                      username
                    }
                }
              }
            }
          } `,
            variables:newproduct.obj
    }).then(()=> {
        commit('getAllSubject', newproduct.params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateSubject({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$idclient:Int,$description:String,$status:String,$financier:String,$typecase:String,$typepayment:String,$statuspayment:String,$lawyer:Int,$cause:String,$cost:Decimal,$idFranq:Int)
          {
            updatesubject(id:$id,idclient:$idclient,description:$description,status:$status,typecase:$typecase,typepayment:$typepayment,financier:$financier,statuspayment:$statuspayment,lawyer:$lawyer,cause:$cause,cost:$cost,franq:$idFranq)
            {
              info
              {
                id,
                description,
                totalCost,
                client{ 
                  id,
                  user{
                    id,
                    username
                  }
                }
              }
            }
          } `,
            variables:newproduct.obj
    }).then(()=> {
      commit('getAllSubject', newproduct.params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateSubject({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int)
          {
            changestatesubject (id:$id)
            {
              state 
            }
          }   `,
            variables:newproduct.obj
    }) .then(()=> {
          commit('getAllSubject', newproduct.params);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddClient({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($username:String,$email:String,$firstname:String,$lastname:String,$password:String,$idFranq:Int,$lawyers:String)
          {
          addclient(username:$username,email:$email,firstName:$firstname,lastName:$lastname,password:$password, franq:$idFranq,lawyers:$lawyers)
            {
              info
              {
                id,
                description,
                date,
                user{
                  username
                }
              }
            }
          } `,
            variables:newproduct.newuser
    }) .then(()=> {
            if(newproduct.location === true)
                commit('getAllUser', false);
            else{
                if(newproduct.admin === true){
                    commit('getAllClient', {
                        filter: false,
                        idFranq: -1,
                        id_lawyer: -1
                    });
                }
                else{
                    commit('getAllClient', {
                        filter: false,
                        idFranq: newproduct.idFranq,
                        id_lawyer: newproduct.id_lawyer
                    });
                }
            }
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateClient({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$username:String,$email:String,$firstname:String,$lastname:String,$lastRole:String,$idFranq:Int,$lawyers:String)
          {
          updateclient(id:$id,username:$username,email:$email,firstName:$firstname,lastName:$lastname,lastRole:$lastRole,franq:$idFranq,lawyers:$lawyers)
            {
              info
              {
                id,
                description,
                date,
                user{
                    username
                },
                active,
                remove
              }
            }
          } `,
            variables:newproduct.newuser
    }).then(()=> {
            if(newproduct.location === true)
                commit('getAllUser', false);
            else{
                if(newproduct.admin === true){
                    commit('getAllClient', {
                        filter: false,
                        idFranq: -1,
                        id_lawyer: -1
                    });
                }
                else{
                    commit('getAllClient', {
                        filter: false,
                        idFranq: newproduct.idFranq,
                        id_lawyer: newproduct.id_lawyer
                    });
                }
            }
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateClient({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int)
          {
            changestateclient (id:$id)
            {
              state 
            }
          }   `,
            variables:newproduct
    }).then(()=> {
          if(newproduct.admin === true){
              commit('getAllClient', {
                  filter: false,
                  idFranq: -1,
                  id_lawyer: -1
              });
          }
          else{
              commit('getAllClient', {
                  filter: false,
                  idFranq: newproduct.idFranq,
                  id_lawyer: newproduct.id_lawyer
              });
          }
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddLawyer({commit},newproduct)
    {
       axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($username:String,$email:String,$firstname:String,$lastname:String,$password:String,$franchise:String,$specialty:String,$idFranq:Int)
          {
          addlawyer(firstName:$firstname,lastName:$lastname,username:$username,email:$email,password:$password, specialty:$specialty,franchise:$franchise,franq:$idFranq)
            {
              info
              {
                id,
                description,
                date,
                franchise{
                    description
                },
                user{
                    username
                }
                specialty{
                    description
                }
              }
            }
          } `,
            variables:newproduct.newuser
    }).then(()=> {
        if(newproduct.location === true)
            commit('getAllUser', false);
        else{
            if(newproduct.admin === true){
                commit('getAllLawyer',{
                    id:newproduct.idfranchise,
                    filter: false,
                    idFranq:-1
                });
            }
            else {
                commit('getAllLawyer',{
                    id:newproduct.idfranchise,
                    filter: false,
                    idFranq:newproduct.idFranq
                });
            }
        }
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateLawyer({commit},newproduct)
    {
       axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$username:String,$email:String,$firstname:String,$lastname:String,$franchise:String,$specialty:String,$lastRole:String,$idFranq:Int)
          {
          updatelawyer(id:$id,username:$username,email:$email,firstName:$firstname,lastName:$lastname, specialty:$specialty,franchise:$franchise,lastRole:$lastRole,franq:$idFranq)
            {
              info
              {
                id,
                description,
                date,
                active,
                remove,
                franchise{
                    description
                },
                user{
                    username
                },
                specialty{
                    description
                }
              }
            }
          } `,
            variables:newproduct.newuser
    }).then(()=> {
            if(newproduct.location === true)
                commit('getAllUser', false);
            else
                commit('getAllLawyer',{
                    id:newproduct.idfranchise,
                    filter: false,
                    idFranq:newproduct.idFranq
                });
    }).catch((error)=> {
        console.log(error);
    });
    },
      ChangeStatusLawyer({commit},newproduct)
      {
          axios.post(`${urlmaster}/graphql/`, {
              query: ` 
          mutation
          {
          changestatelawyer(id:${newproduct.id})
            {
              state
            }
          } `
          }).then(()=> {
              if(newproduct.admin === true){
                  commit('getAllLawyer',{
                      id:newproduct.idfranchise,
                      filter: false,
                      idFranq: -1
                  });
              }
              else{
                  commit('getAllLawyer',{
                      id:newproduct.idfranchise,
                      filter: false,
                      idFranq: newproduct.idFranq
                  });
              }
          }).catch((error)=> {
              console.log(error);
          });
      },
    ChangeStateLawyer:  function({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int)
          {
            changestatelawyer (id:$id)
            {
              state
            }
          }   `,
            variables:newproduct
    }) .then(function () {
          commit('getAllFranchise', false);
    }).catch(function (error) {
        console.log(error);
    });
    },
    AddFranchise({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idcity:Int,$description:String)
          {
            addfranchise(idCity:$idcity,description:$description)
            {
              info
              {id}
            }
          } `,
            variables:newproduct
    }).then(()=> {
          commit('getAllFranchise', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateFranchise({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($id:Int,$idcity:Int,$description:String)
          {
            updatefranchise(id:$id,idCity:$idcity,description:$description)
            {
              info
              {id}
            }
          } `,
        variables:newproduct
    }).then(()=> {
          commit('getAllFranchise', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateFranchise({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int)
          {
            changestatefranchise (id:$id)
            {
              state 
            }
          }   `,
            variables:newproduct
    }).then(()=> {
          commit('getAllFranchise', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddStatus:  function({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($description:String)
          {
            addstatus(description:$description)
            {
              info
              {
                id,description
              }
            }
          }  `,
            variables:newproduct
    }) .then(()=> {
          commit('getAllStatus', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateStatus({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int,$description:String)
          {
            updatestatus (id:$id,description:$description){
                info
                {
                  id, 
                  description
                }
            }
          }   `,
            variables:newproduct
    }).then(()=> {
        commit('getAllStatus', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateStatus({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int)
          {
            changestatestatus (id:$id)
            {
              state 
            }
          }   `,
            variables:newproduct
    }).then(()=> {
          commit('getAllStatus', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddState:  function({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($description:String)
          {
            addstate(description:$description)
            {
              info
              {
                id,description
              }
            }
          }  `,
            variables:newproduct
    }) .then(function () {
          commit('getAllState', false);
    }).catch(function (error) {
        console.log(error);
    });
    },
    UpdateState({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int,$description:String)
          {
            updatestate (id:$id,description:$description){
                info {
                      id,
                      description
                }
            }
          }   `,
            variables:newproduct
    }).then(()=> {
        commit('getAllState', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateState({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation ($id:Int)
          {
            changestatestate (id:$id)
            {
              state 
            }
          }   `,
            variables:newproduct
    }).then(()=> {
          commit('getAllState', false);
    }).catch((error)=> {
         console.log(error);
       });
    },
    AddCategory:  function({commit},newclient)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($description:String,$cost:Decimal)
{
  addcategory(description:$description,cost:$cost)
  {
    info{id,description}
  }
}  `,
        variables:newclient
    }) .then(()=> {
          commit('getAllCategory', false);
                        })
       .catch(function (error) {
         console.log(error);
       });
    },
    UpdateCategory({commit},newclient)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($id:Int,$description:String,$cost:Decimal)
{
  updatecategory(id:$id,description:$description,cost:$cost)
  {
    info{id,description}
  }
}  `,
        variables:newclient
    }).then(()=> {
        commit('getAllCategory', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
      ChangeStateCategory({commit},newclient)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($id:Int)
        {
          changestatecategory(id:$id)
          {
            state
          }
        }  `,
        variables:newclient
    }).then(()=> {
        commit('getAllCategory', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddTypeCase({commit},newtypecase)
    {

        axios.post(`${urlmaster}/graphql/`, {
      query: ` 
      mutation ($description:String)
        {
          addtypecase(description:$description)
          {
            info{id,description}
          }
        }  `,
        variables:newtypecase
    }).then(()=> {
        commit('getAllTypeCase', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateTypeCase({commit},newtypecase)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int,$description:String)
      {
        updatetypecase(id:$id,description:$description)
          {
            info{id,description}
          }
      } `,
        variables:newtypecase
    }).then(()=> {
          commit('getAllTypeCase', false);
                        })
       .catch((error)=> {
         console.log(error);
       });
    },
    ChangeStateTypeCase({commit},newtypecase)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int)
      {
        changestatetypecase(id:$id)
        {
          state
        }
      }`,
        variables:newtypecase
    }).then(()=> {
          commit('getAllTypeCase', false);
                        })
       .catch((error)=> {
         console.log(error);
       });
    },
    AddSpecialty({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($description:String)
      {
        addspecialty(description:$description)
      {
        info
        {
          id,description
        }
      }
          }`,
        variables:data
    }).then(()=> {
          commit('getAllSpecialty', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateSpecialty({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int,$description:String)
      {
        updatespecialty(id:$id,description:$description)
          {
            info
            {
              id,description
            }
          }
              }`,
        variables:data
    }).then(()=> {
          commit('getAllSpecialty', false);
    }).catch((error)=> {
         console.log(error);
    });
    },
    ChangeStateSpecialty({commit},newtypecase)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int)
      {
        changestatespecialty(id:$id)
        {
          state
        }
      }`,
        variables:newtypecase
    }).then(()=> {
          commit('getAllSpecialty', false);
    })
       .catch((error)=> {
         console.log(error);
       });
    },
    AddStatusPayment({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($description:String)
      {
        addstatuspayment(description:$description)
      {
        info
        {
          id,description
        }
      }
          }`,
        variables:data
    }) .then(()=> {
          commit('getAllStatusPayment', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateStatusPayment({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int,$description:String)
      {
        updatestatuspayment(id:$id,description:$description)
          {
            info
            {
              id,description
            }
          }
      }`,
        variables:data
    }).then(()=> {
          commit('getAllStatusPayment', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateStatusPayment({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int)
      {
        changestatestatuspayment(id:$id)
        {
          state
        }
      }`,
        variables:data
    }).then(()=> {
          commit('getAllStatusPayment', false);
    })
       .catch((error)=> {
         console.log(error);
       });
    },
    AddFormPayment({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($description:String)
      {
        addformpayment(description:$description)
          {
            info
            {
              id,description
            }
          }
      }`,
        variables:data
    }).then(()=> {
          commit('getAllFormPayment', false);
                        })
       .catch((error)=> {
         console.log(error);
       });
    },
    UpdateFormPayment({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int,$description:String)
      {
        updateformpayment(id:$id,description:$description)
          {
            info
            {
              id,description
            }
          }
      }`,
        variables:data
    }).then(()=> {
          commit('getAllFormPayment', false);
                        })
       .catch((error)=> {
         console.log(error);
       });
    },
    ChangeStateFormPayment({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int)
      {
        changestateformpayment(id:$id)
        {
          state
        }
      }`,
        variables:data
    }).then(()=> {
          commit('getAllFormPayment', false);
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddFinancier({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($idState:Int,$description:String,$idFranq:Int)
      {
        addfinancier(idState:$idState,description:$description,franq:$idFranq)
          {
            info
            {
              id,
              description
            }
          }
      }`,
        variables:data
    }) .then(()=> {
        if(data.admin === true){
            commit('getFinancierFranq', -1);
        }
        else{
            commit('getFinancierFranq', data.idFranq);
        }
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateFinancier({commit},data) {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int,$idState:Int,$description:String,$idFranq:Int)
      {
        updatefinancier(id:$id,idState:$idState,description:$description,franq:$idFranq)
          {
            info
            {
              id,
              description
            }
          }
      }`,
        variables:data
    }).then(()=> {
            if(data.admin === true){
                commit('getFinancierFranq', -1);
            }
            else{
                commit('getFinancierFranq', data.idFranq);
            }
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateFinancier({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int)
      {
        changestatefinancier(id:$id)
        {
          state
        }
      }`,
        variables:data
    }).then(()=> {
          if(data.admin === true){
              commit('getFinancierFranq', -1);
          }
          else{
              commit('getFinancierFranq', data.idFranq);
          }
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddCity({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($idState:Int,$description:String)
      {
        addcity(idState:$idState,description:$description)
        {
          info
          {
            id,description,active,
            state
            {
              id,description
            }
          }
        }
      }`,
        variables:data
    }).then(()=> {
        commit('getAllCity',{
            id:data.idstate,
            filter:false
        });
    }).catch((error)=> {
        console.log(error);
    });
    },
    UpdateCity({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int,$idState:Int,$description:String)
      {
        updatecity(id:$id,idState:$idState,description:$description)
        {
          info
          {
            id,description,active,
            state
            {
              id,description
            }
          }
        }
      }`,
        variables:data
    }).then(()=> {
          commit('getAllCity',{id:data.idstate, filter:false});
    }).catch((error)=> {
        console.log(error);
    });
    },
    ChangeStateCity({commit},data)
    {
        axios.post(`${urlmaster}/graphql/`, {
      query: ` mutation ($id:Int)
      {
        changestatecity(id:$id)
        {
          state
        }
      }`,
        variables:data
    }).then(()=> {
        commit('getAllCity',{id:data.idstate, filter:false});
    }).catch((error)=> {
        console.log(error);
    });
    },
    AddSubjectDocument({commit},newpromotion)
    {
      let data = new FormData();
      data.append('operations', `{"query":"mutation ($file: Upload,$idsubject:Int,$description:String) {\\n  addsubjectdocument(idsubject:$idsubject, doc: $file, description:$description) {\\n    info {\\n      id\\n      }\\n  }\\n}","variables":{"idsubject":"${newpromotion.obj.idsubject}","file":null,"description":"${newpromotion.obj.description}"},"operationName":null}`);
      data.append('map', '{"0":["variables.file"]}');
      data.append('0', newpromotion.obj.file);
      let config = {
        method: 'post',
       url:`${urlmaster}/graphql/`,
        headers: { 
          'Cookie': document.cookie,
                },
        data : data
      };
      axios(config)
      .then((response)=> {
          console.log(JSON.stringify(response.data));
      }).catch((error)=> {
        console.log(error);
      }).finally(()=>{
          let params = {...newpromotion.params};
          params.id = newpromotion.obj.idsubject;
          commit('getAllSubject',params);
      });
    },
    RemoveSubjectDocument({commit},newproduct)
    {
        axios.post(`${urlmaster}/graphql/`, {
       query: ` 
          mutation($idsubject:Int,$id:Int)
          {
            removesubjectdocument(idsubject:$idsubject,id:$id)
            {
              info
              {
                id
              }
            }
          } `,
            variables:newproduct.obj
    }) .then(function () {
			let params = {...newproduct.params};
            params.id = newproduct.obj.idsubject;
            commit('getAllSubject',params);
    }).catch(function (error) {
        console.log(error);
    });
    },
  },// Fin de Action************************************************************************** */
  getters: {
    GET_TOKEN(state)
        {
          return state.token;
        }
  },
});