export function canAccess(role, url) {
    let can = false;
    if (role === undefined){
        role = "Public";
    }
    roles.filter((value)=>value.name === role)[0].urls.forEach((value => {
        if (value === url){
            can = true;
        }
    }));
    return can;
}

const roles = [
    {
        name: "Public",
        urls: [
            "/",
            "/authentication/login",
            "/authentication/boxedlogin",
            "/authentication/fulllogin",
            "/authentication/boxedregister",
            "/authentication/fullregister",
            "/authentication/error",
            "/password",
            "/password/sendEmail",
            "/password/confirm",
        ]
    },
    {
        name: "Administrador",
        urls: [
            "/subject/Subjectlist",
            "/subject/Activitylist",
            "/subject/Notelist",
            "/subject/Momentlist",
            "/subject/WorkEmaillist",
            "/subject/SLawyerlist",
            "/subject/Documentlist",
            "/subject/aprov",
            "/subject/pending",
            "/subject/pay",
            "/user/userlist",
            "/user/lawyers",
            "/user/ClientList",
            "/user/ClientInbox",
            "/user/DLawyerList",
            "/user/franq",
            "/user/financy",
            "/user/operator",
            "/codif/StatusPaymentlist",
            "/codif/Specialtylist",
            "/codif/Statuslist",
            "/codif/Citylist",
            "/codif/TypeCaselist",
            "/codif/Categorylist",
            "/codif/Franchiselist",
            "/codif/Statelist",
            "/codif/Financierlist",
            "/codif/FormPaymentlist",
            "/receipt/receiptlist",
            "/visit/VisitList",
            "/dashboards/analytical",
            "/dashboards/modern",
            "/dashboards/demographical",
            "/dashboards/minimal",
            "/security",
            "/chat",
            "/chat/chat",
        ]
    },
    {
        name: "Abogado",
        urls: [
            "/subject/Subjectlist",
            "/subject/Activitylist",
            "/subject/Notelist",
            "/subject/Momentlist",
            "/subject/WorkEmaillist",
            "/subject/Documentlist",
            "/subject/aprov",
            "/subject/pending",
            "/subject/pay",
            "/user/ClientList",
            "/user/ClientInbox",
            "/codif/TypeCaselist",
            "/codif/Categorylist",
            "/chat",
            "/chat/chat",
        ],
        redirect: "/user/ClientList"
    },
    {
        name: "Cliente",
        urls: [
            "/subject/Subjectlist",
            "/subject/Activitylist",
            "/subject/Notelist",
            "/subject/Momentlist",
            "/subject/WorkEmaillist",
            "/subject/Documentlist",
            "/user/ClientInbox",
            "/chat",
            "/chat/chat",
        ],
        redirect: "/subject/Subjectlist"
    },
    {
        name: "Franquiciaria",
        urls: [
            "/subject/Subjectlist",
            "/subject/Activitylist",
            "/subject/Notelist",
            "/subject/Momentlist",
            "/subject/WorkEmaillist",
            "/subject/Documentlist",
            "/subject/aprov",
            "/subject/pending",
            "/subject/pay",
            "/user/ClientList",
            "/user/ClientInbox",
            "/user/DLawyerList",
            "/user/financy",
            "/visit/VisitList",
            "/dashboards/analytical",
        ],
        redirect: "/"
    },
    {
        name: "Financiera",
        urls: [
            "/subject/Subjectlist",
            "/subject/Activitylist",
            "/subject/Notelist",
            "/subject/Momentlist",
            "/subject/WorkEmaillist",
            "/subject/Documentlist",
            "/subject/aprov",
            "/subject/pending",
            "/subject/pay",
            "/user/ClientInbox",
            "/visit/VisitList",
            "/dashboards/analytical",
        ],
        redirect: "/subject/Subjectlist"
    },
    {
        name: "Operador",
        urls: [
            "/user/ClientInbox"
        ],
        redirect: "/subject/WorkMail"
    }
];