import Vue from "vue";
import App from "./App.vue";
import axios from 'axios';
import VueAxios from 'vue-axios';
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import 'bootstrap-icons/bootstrap-icons.svg';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vuetify/dist/vuetify.min.css';
import VueSkycons from "vue-skycons";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import VueRouterUserRoles from "vue-router-user-roles";
import Vuelidate from 'vuelidate';
import {recurso, websocket} from './store/store';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import VueApollo from 'vue-apollo';
import VcPiechart from 'vc-piechart';
import 'vc-piechart/dist/lib/vc-piechart.min.css';
import Loading from 'loading-vue-indicator';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueScrollTo from 'vue-scrollto';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import 'vue-croppa/dist/vue-croppa.css';
import VCalendar from 'v-calendar';
// import * as VueGoogleMaps from 'vue2-google-maps'
import VPhoneNumber from 'v-phone-number';
import Croppa from 'vue-croppa';
import './style/status.css';

const httpLink = new HttpLink({
  uri: recurso+'/graphql',
});

const wsLink = new WebSocketLink({
  uri: websocket,
  options: {
    reconnect: true,
  },
});

const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
    },
    wsLink,
    httpLink
);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(Toast, {});
Vue.use(VueApollo);
Vue.use(VueScrollTo);
Vue.use(VueAxios, axios);
Vue.use(VueFeather);
Vue.use(Vuelidate);
Vue.use(VcPiechart);
Vue.use(FloatingVue);
Vue.use(Croppa);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.use(VueSweetalert2);
Vue.component('v-phone-number', VPhoneNumber);
Vue.component('loading', Loading);
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
/*Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDGUVMQH1s9Nx4tuQ8EXUOiBpU3J4ig4vc',
    libraries: 'places',
  }
});*/
Vue.use(InstantSearch);
Vue.use(VueRouterUserRoles, { router: router });
Vue.config.productionTip = false;
Vue.use(Vuebar);

new Vue({
  vuetify,
  apolloProvider,
  render: (h) => h(App),
  store,
  router
}).$mount("#app");
if(store.state.token == null){
  router.push({ path: "/authentication/login" });
}